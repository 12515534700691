<template>
	<div class="">
		<top :bgWhite='true'></top>
		<div class="contentAll  padding-lr-100  top-100 padding-bottom-50">
			<el-row :gutter="24">
				<el-col :span="18">
					<div class=" flex margin-bottom " >
						<div class="book-cover margin-right-sm" v-if="data && data.thumb">
							<img :src="data.thumb | filterArrays">
							<div class="ftag fs12" :class="data.is_free==1 ? 'free_tag' :'fee_tag' ">
								{{data.is_free==1 ? '免费' : '收费'}}
							</div>
						</div>
						<div class="right-info flex flex-direction  justify-around w100">
							<div class="flex align-center ">
								<div class="flex align-center title-box">
									<p class="book-title margin-right-10 text-cut">{{data.title || '--'}}</p>
									<div class="ftag fs12" :class="data.is_continued==1 ? 'free_tag' :'fee_tag' ">
										{{data.is_continued==1 ? '连载' : '完结'}}
									</div>
								</div>
								<div class="flex align-center">
									<img src="../../assets/image/icon/push.png" class="block-18 margin-right-5">
									<p class="fs9">发布时间：{{data.create_time || '--'}}</p>
								</div>
							</div>
							<!-- 收费 -->
							<div class="" v-if="data.is_free==0">
								<span class="fs14">售价：</span>
								<span class="fs16 bold text-theme">¥{{data.money || '0.00'}}</span>
							</div>
							<div class="flex align-center justify-between">
								<!-- 作者信息 -->
								<div class="" style="width: 50%;">
									<div class="flex align-center writer-info ">
										<div class="head-box">
											<el-image :src="user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
											<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="user.is_v==1">
										</div>
										<!-- <img :src="user.head_img" class="block-40 margin-right-xs radius-40"> -->
										<div class="left-info">
											<p class="name">{{user.user_nickname || '--'}}</p>
											<div class="writer-tag flex align-center margin-top-5" v-if="user.tags_info && user.tags_info.length>0 && user.tags_info[0]!=''">
												<p type="warning" size="mini" v-if="j<2" class="margin-right-5" v-for="(ele,j) in user.tags_info" :key="j">{{ele}}</p>
											</div>
										</div>
									</div>
									
									<el-row :gutter="24" class="margin-top-20">
										<el-col :span="6">
											<div class="point flex align-center">
												<el-popover
													placement="bottom"
													title=""
													width="200"
													trigger="hover">
													<div class="point">
														<div class="flex align-center"  @click="copyUrl(data)">
															<img src="../../assets/image/icon/copy.png" class="block-20 margin-right-10">
															<p class="text-666">复制链接</p>
														</div>
														<div class="margin-top flex align-center" @click="transpond(data)" v-if="data.ducoment_type!=0">
															<img src="../../assets/image/icon/transpond.png" class="block-24 margin-right-10">
															<p class="text-666">转发</p>
														</div>
													</div>
													<div class="flex align-center" slot="reference">
														<img src="../../assets/image/home/share.png" class="block-20 margin-right-5">
														<span class="fs14 text-999">{{data.transmit_num || 0}}</span>
													</div>
												</el-popover>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple">
												<div class=" flex align-center">
													<img src="../../assets/image/icon/view.png" class="block-20 margin-right-5">
													<span class="fs16 text-999">{{data.read_num || 0}}</span>
												</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="grid-content bg-purple">
												<div class="flex align-center point" @click="giveLike">
													<transition :name="data.is_like==1 ? 'zoom' : '' " mode="out-in">
														<img src="../../assets/image/home/zan1.png" class="block-20 margin-right-5" v-if="data.is_like==1" key="like">
														<img src="../../assets/image/home/zan.png" class="block-20 margin-right-5" v-else key="unlike">
													</transition>
													<span class="fs16" :class="data.is_like==1 ? 'text-theme' : 'text-999' ">{{data.like_num || 0}}</span>
												</div>
											</div>
										</el-col>
										<!-- <el-col :span="6">
											<div class="grid-content bg-purple">
												<div class=" flex align-center">
													<img src="../../assets/image/home/message.png" class="block-20 margin-right-5">
													<span class="fs16 text-999">{{data.comm_num || 0}}</span>
												</div>
											</div>
										</el-col> -->
										<!-- <el-col :span="6">
											<div class="grid-content bg-purple" @click="toCollect" >
												<div class="point flex align-center">
													<transition :name=" data.is_collect==1 ? 'zoom' : '' " mode="out-in">
														<img src="../../assets/image/icon/collect_fill.png" class="block-22 margin-right-5" v-if="data.is_collect==1" key="collect">
														<img src="../../assets/image/icon/collect.png" class="block-22 margin-right-5" v-else key="uncollect">
													</transition>
													<span class="fs14 text-666" style="flex-shrink: 0;">{{data.is_collect==1 ? '取消收藏' :'收藏'}}</span>
												</div>
											</div>
										</el-col> -->
									</el-row>
								</div>
								<!-- 按钮组 -->
								<div class="flex flex-direction handle-btn">
									<el-button type="danger" plain round @click="toCatalog">全部目录</el-button>
									<el-button type="danger" plain round class="margin-top-20" @click="toCatalog">开始阅读</el-button>
								</div>
							</div>
							<!-- 内容概述 -->
							<div class="">
								<p class="fs9">内容概述</p>
								<p class="fs9 text-666 margin-top-5">{{data.synopsis || '暂无相关内容'}}</p>
							</div>
						</div>
						</div>
						<!-- 评价模块 -->
						<div class="">
							<div class="">
								<div class="">
									<div class="eva_title">
										<span class="fs18 margin-right-5 bold">评论</span>
										<span class="fs14">{{data.comm_num || 0}}</span>
									</div>
									<div class="flex align-center justify-between margin-tb-20">
										<div class="flex align-center">
											<img :src="userInfo.head_img" class="block-40 margin-right-10 radius-40">
											<div class="input-boxs flex align-center padding-lr-xs justify-between">
												<input type="text" name="" v-model="contents" id="" value="" class="fs12 w95" placeholder="写评论…"/>
												<el-popover
												  placement="right"
												  width="400"
												  trigger="click">
												  <emotion @emotion="handleEmotion" :height="200" ref="emojiPop"></emotion>
												  <img slot="reference" src="../../assets/image/icon/smile.png" class="block-28 point">
												</el-popover>
											</div>
										</div>
										<div class="fs12">
											<el-button type="danger"  round  @click="postComment">评论</el-button>
										</div>
									</div>
								</div>
							</div>
							<div class="">
								<div v-for="(item,index) in lists" :key="index" class="margin-bottom-22">
									<evaluate-item :info="item" :can_reply="false" :writer_id="data.user_id" :aid="aid" @refresh="getCommentLists"></evaluate-item>
								</div>
							</div>
							<!-- 分页 -->
							<div class="flex align-center justify-center">
								<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='15' :hide-on-single-page='true' @current-change="currentChange">
								</el-pagination>
							</div>
						</div>
				</el-col>
				<el-col :span="6">
					<div class="writer-box flex flex-direction align-center justify-between">
						<div class="info-tag fs9 text-white">
							发布人
						</div>
						<div class="w100">
							<!-- 作者信息 -->
							<div class="flex flex-direction align-center justify-around">
								<div >
									<div class="head-box">
										<el-image :src="user.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
										<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="user.is_v==1">
									</div>
									<!-- <img :src="user.head_img" class="radius-40 block-40"> -->
								</div>
								<p class="name margin-top-5">{{user.user_nickname}}</p>
								<p class="text-999 fs9 margin-tb-10">{{user.fans_num || 0}}人关注</p>
								<el-button type="danger" size="mini" v-if="user.id!=userInfo.id"  @click="addFocus">{{data.is_focus==1 ? '已关注' :'+ 关注'}}</el-button>
							</div>
							<!-- 作者介绍 -->
							<div class="margin-top-40 flex flex-direction  align-start">
								<p class="intro_title fs13 margin-bottom-15">作者介绍</p>
								<p class="intro_content fs12 text-left text-cut-3">{{user.autograph || '暂无相关内容'}}</p>
							</div>
						</div>
						<!-- 底部版权 -->
						<div class="fs12 margin-top-50">
							<span class="text-666">版权归原作者所有，如有侵权请立即与我们联系，我们将及时处理，感谢您的理解。</span>
							<el-button type="text" class="text-orange" disabled v-if="userInfo.id==user.id">版权投诉</el-button>
							<router-link :to="{path:'/complain',query:{aid:aid}}" v-else>
								<el-button type="text" class="text-orange" >版权投诉</el-button>
							</router-link>
						</div>
					</div>
				</el-col>
			</el-row>
			
		</div>
		<bottom></bottom>
	</div>
</template>

<script>
	import Emotion from '@/components/Emotion/index'
	import evaluateItem from '@/components/evaluate/evalaute.vue';
	import top from '@/components/top';
	import bottom from '@/components/bottom';
	export default {
		components: {
			evaluateItem,
			top,
			bottom,
			Emotion
		},
		name: 'fictionDetail',
		filters:{
			filterArrays(val){
				return val[0]
			}
		},
		data(){
			return{
				baseUrl:this.imgUrl,
				page:1, //当前页数
				totalSize:0, //评论总数
				aid:'', //电子书id
				lists:[],
				contents:'', //评论内容
				user:{}, //作者信息
				data:{} ,//详情信息
				status:'more'
			}
		},
		computed: {
			userInfo() {
				return this.$store.getters.userInfo;
			}
		},
		mounted() {
			this.aid = this.$route.query.aid;
			this.getBookDetail() //获取电子书详情
			this.getCommentLists() //获取评论列表
		},
		methods:{
			// 复制链接
			copyUrl(item){
				let invitelink = `${this.baseUrl}/index/index/pc/#/fictionDetail?article_id=${item.aid}&atype=3`;
				this.$copyText(invitelink).then(
					res => {
						this.$message.success('成功复制到剪贴板')
					},
					err => {
						console.log(err) // 同上
					}
				)
			},
			// 转发
			transpond(item){
				this.$router.push({
					path:'/transpond',
					query:{
						ducoment_type:item.ducoment_type,
						aid:item.aid
					}
				})
			},
			// 点赞
			giveLike(){
				this.ajax('post', '/v1/61419389e89d5', {
					article_id: this.data.aid
				}, res => {
					if (res.code == 1) {
						this.data.is_like = this.data.is_like ==1 ? 0 : 1;
						if(this.data.is_like ==1){
							this.data.like_num +=1
						}else{
							this.data.like_num -=1
						}
						window.opener.getSonData()
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 加关注
			addFocus(){
				this.ajax('post', '/v1/61419cb4727bd', {
					fuid: this.data.user_id
				}, res => {
					if (res.code == 1) {
						this.data.is_focus = this.data.is_focus == 1 ? 0 : 1
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 收藏
			toCollect(){
				this.ajax('post', '/v1/6141a0f6bd7d0', {
					article_id: this.aid,
					type:3
				}, res => {
					if (res.code == 1) {
						this.data.is_collect = this.data.is_collect ==1 ? 0 : 1;
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 跳转章节目录
			toCatalog(){
				this.$router.push({
					path:'/catalog',
					query:{
						aid:this.aid
					}
				})
			},
			// 点击当前页
			currentChange(e){
				this.page = e;
				this.getCommentLists()
			},
			// 提交评论
			postComment() {
				if(!this.contents.trim()){
					this.$message.error('请先输入评论内容');
					return false;
				}
				this.ajax('post', '/v1/6151764c066ea', {
					document_id: this.aid,
					content:this.contents
					// id:'' //回复谁传谁的id
				}, res => {
					if (res.code == 1) {
						this.$message.success('评论成功');
						this.contents = '';
						this.status = 'more';
						this.getCommentLists();
						this.getBookDetail()
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取电子书详情
			getBookDetail() {
				this.ajax('post', '/v1/613afc809f5e3', {
					article_id: this.aid
				}, res => {
					if (res.code == 1) {
						document.title = res.data.title;
						this.user = res.data.user;
						this.data = res.data
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取评论列表
			getCommentLists() {
				this.ajax('post', '/v1/61516d9c4dc60', {
					page:this.page,
					document_id:this.aid,
					id:'' //回复谁传谁的id
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data.map(item=>{
							item.is_open = false;
							return item
						})
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			handleEmotion (i) {
			  this.contents += i
			},
			// 将匹配结果替换表情图片
			emotion (res) {
			  let word = res.replace(/\#|\;/gi,'')
			  const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
			  let index = list.indexOf(word)
			  return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`   
			}
		}
	}
</script>

<style  lang="scss">
	html,
	body {
		background: #FFFFFF !important;
	}
	.contentAll{
		width: 70%;
	}
	.head-box{
		position: relative;
		.bigv{
			position: absolute;
			bottom: 5px;
			right: 5px;
		}
	}
	/** 动画进行时的class **/
	.zoom-enter-active, .zoom-leave-active {
	    transition: all .15s cubic-bezier(0.42, 0, 0.34, 1.55);
	}
	
	/** 设置进场开始的状态和离场结束的状态，都是缩放到0 **/
	.zoom-enter, .zoom-leave-to {
	    transform: scale(0);
	}
	
	/** 设置进场结束的状态和离场开始的状态, 都是缩放到1 **/
	.zoom-enter-to, .zoom-leave {
	    transform: scale(1);
	}
	.list-item{
		padding-bottom: 20px;
		border-bottom: 1px solid #eeeeee;
	}
	.eva_title{
		position:relative;
		z-index:99;
		&:after{
			content: '';
			position:absolute;
			left:0;
			bottom:0;
			width: 75px;
			height: 7px;
			background: linear-gradient(89deg, rgba(229, 79, 66, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
			z-index:2
		}
	}
	.input-boxs{
		width: 686px;
		height: 48px;
		background: #F6F7F9;
		opacity: 1;
		border-radius: 5px;
		input{
			background: #F6F7F9;
		}
	}
.book-cover {
		position: relative;
		flex-shrink: 0;
		width: 172px;
		height: 242px;
		background: rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 8px 0px 0px 8px;
		img {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 8px 0px 0px 8px;
		}
		.ftag {
			position: absolute;
			top: 0;
			right: 0;
			width: 36px;
			text-align: center;
			height: 20px;
			line-height: 20px;
			border-radius: 0 0 0 10px;
			font-weight: bold;
		}
		
		.free_tag {
			color: #31CBA4;
			background: #CAF4D7;
		}
		
		.fee_tag {
			color: #FF7F37;
			background: #FDEDEB;
		}
	}

	.right-info {
		height: 242px;
		.title-box{
			.ftag {
				margin: 0 10px;
				width: 36px;
				text-align: center;
				height: 20px;
				line-height: 20px;
				border-radius: 0 0 0 10px;
				font-weight: bold;
			}
			
			.free_tag {
				color: #31CBA4;
				background: #CAF4D7;
			}
			
			.fee_tag {
				color: #FF7F37;
				background: #FDEDEB;
			}
		}
		
	}
.intro_content{
			text-align: left;
		}
	.book-title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}

	.writer-info {
		.head-img {
			width: 40px;
			height: 40px;
		}

		.left-info {
			.name {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 66px;
				height: 20px;
				text-align: center;
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #FFFFFF;
				opacity: 1;
				&:nth-child(1){
					background: url(../../assets/image/icon/label-bg1.png) no-repeat;
					background-size: cover;
				}
				&:nth-child(2){
					background: url(../../assets/image/icon/label-bg2.png) no-repeat;
					background-size: cover;
				}
			}
		}
	}
	.handle-btn{
		.el-button{
			width: 148px;
			height: 40px;
			line-height: 5px;
			border: 1px solid #E54F42;
			opacity: 1;
			border-radius: 20px;
			font-size: 12px;
		}
		.el-button+.el-button{
			margin-left: 0;
		}
	}
	.writer-box{
		width: 100%;
		background: #F6F7F9;
		position: relative;
		padding: 20px;
		.info-tag{
			width: 46px;
			height: 20px;
			padding:2px;
			text-align: center;
			border: 1px solid #FFFFFF;
			background: linear-gradient(317deg, #74E392 0%, #2CC9A5 100%);
			opacity: 1;
			border-radius: 0px 12px 12px 12px;
			position: absolute;
			top: 0;
			left: 0;
		}
		.head-img{
			width: 48px;
			height: 48px;
			border-radius: 50%;
			img {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
		.text-orange{
			color: #E54F42;
		}
	}
</style>